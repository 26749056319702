<template>
  <div class="flex justify-center flex-col items-center gap-y-2">
    <div v-if="back" class="cursor-pointer" @click="advance">
      <span class="font-monument text-xs text-gray-dark">↑ Previous Step</span>
    </div>
    <InputButton v-else :disabled="disabled" @click="advance">
      Next
    </InputButton>
    <div
      v-if="!back"
      :class="[
        disabled ? 'opacity-0' : '',
        'hidden md:inline font-monument text-xs text-gray-dark'
      ]"
    >
      enter &#9166;
    </div>
  </div>
</template>
<script setup lang="ts">

const { activeSlide } = useNavigation()
const props = defineProps({
  disabled: {
    type: Boolean,
    default: false
  },
  back: {
    type: Boolean,
    default: false
  }
})

function advance() {
  if (!props.disabled) {
    if (props.back) {
      activeSlide.value--
    } else {
      activeSlide.value++
    }
  }
}

</script>
