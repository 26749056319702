const _useDidntCaptureMediaInput = () => useState<boolean>('didntCaptureMedia', () => false)

const _inputValid = computed(() => {
  const didntCaptureMediaInput = _useDidntCaptureMediaInput().value
  const { validFiles } = useFileManager()
  const withoutAudioFiles = validFiles.value.filter(f => f.type !== 'audio')

  if (didntCaptureMediaInput) { return true }
  if (withoutAudioFiles.length > 0) { return true }
  return false
})

export default function () {
  return {
    didntCaptureMediaInput: _useDidntCaptureMediaInput(),
    inputValid: _inputValid
  }
}
