<template>
  <div class=" w-full mx-auto">
    <div class="flex flex-col paginator justify-center align-center gap-y-2">
      <button
        v-for="(i, idx) in totalSlides - 1"
        :key="i"
        class="w-3 h-3"
        :aria-label="`Slide ${idx}`"
        :data-test="`Slide ${idx}`"
        @click="clickedSlide(idx)"
      >
        <div v-if="(activeIdx === idx && indexInputValid(idx))" class="h-3 bg-space-orange" />
        <div v-else-if="activeIdx === idx" class="h-3 bg-space-orange opacity-70" />
        <div v-else-if="indexInputValid(idx)" class="h-3 flex flex-col justify-center">
          <div class="h-1 bg-space-orange" />
        </div>
        <div v-else class="h-3 flex flex-col justify-center">
          <div class="h-1 bg-gray-medium opacity-80" />
        </div>
      </button>
    </div>
  </div>
</template>
<script setup>
const { totalSlides, activeSlide: activeIdx, setActiveSlide } = useNavigation()

function indexInputValid(idx) {
  return slideIsValid(idx)
}

function clickedSlide(idx) {
  if (idx < totalSlides - 1) {
    setActiveSlide(idx)
  }
}

</script>
<style>
.centered {
  position: fixed;
  /* or absolute */
  top: calc(50%);
  left: 50%;
  width: 0.7rem;
  margin-top: -1.5rem;
  /* margin-left: -1.5rem; */
}
</style>
