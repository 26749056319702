const _useStoryInput = () => useState<string>('storyInput', () => '')
// const _useFileManager = () => useState<string>('fileManager', () => '')

const _inputValid = computed(() => {
  const storyInput = _useStoryInput().value
  const { audioFiles } = useFileManager()
  const textValid = storyInput.trim().length > 0

  const fileValid = audioFiles.value.length > 0

  // const fileValid = files.includes()
  return textValid || fileValid
})

export default function () {
  return {
    storyInput: _useStoryInput(),
    inputValid: _inputValid
  }
}
