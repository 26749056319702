const _useSlideIndex = () => useState<number>('slideIndex', () => 0)
const _totalSlides = 11
const _termsAgreement = () => useState<boolean>('termsAgreement', () => false)

const _invalidSlideIndices = computed(() => {
  const total = _totalSlides
  const invalidIndices = []
  for (let i = 0; i < total - 1; i++) {
    if (!slideIsValid(i)) {
      invalidIndices.push(i)
    }
  }
  return invalidIndices
})

const _allSlidesValid = computed(() => {
  const total = _totalSlides
  for (let i = 0; i < total - 1; i++) {
    if (!slideIsValid(i)) {
      return false
    }
  }
  return true
})

function _nextSlide() {
  const idx = _useSlideIndex()
  if (idx.value < _totalSlides - 1) { idx.value++ }
}

function _prevSlide() {
  const idx = _useSlideIndex()
  if (idx.value > 0) { idx.value-- }
}

function _setActiveSlide(newIdx: number) {
  const idx = _useSlideIndex()
  idx.value = newIdx
}

export default function () {
  return {
    activeSlide: _useSlideIndex(),
    totalSlides: _totalSlides,
    nextSlide: _nextSlide,
    prevSlide: _prevSlide,
    setActiveSlide: _setActiveSlide,
    allSlidesValid: _allSlidesValid,
    invalidSlideIndices: _invalidSlideIndices,
    termsAgreement: _termsAgreement()
  }
}
