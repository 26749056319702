<template>
  <div class="w-full h-[100dvh] sm:min-h-screen xl:container xl:mx-auto flex flex-col md:flex-row">
    <div :class="['flex-col md:basis-1/2 flex p-3 md:p-6 md:max-h-screen', { 'flex-grow': singlePane }]">
      <div class="flex justify-between pb-6 pr-1">
        <!-- <svg src="/wordmark.svg" alt="Enigma Labs"></svg> -->
        <a href="//enigmalabs.io" target="_blank">
          <img src="/wordmark.svg" alt="Enigma Labs">
        </a>
        <div v-if="uploading">
          <span class="font-monument text-xs text-space-orange">Uploading report</span>
        </div>
        <div v-else-if="submitted">
          <span class="font-monument text-xs">Submitted</span>
        </div>
        <ControlNextSlide v-else-if="back" back />
      </div>
      <slot name="left" />
      <div v-if="forward" class="pt-6">
        <ControlNextSlide data-test="Next Slide" :disabled="props.forwardDisabled" />
      </div>
      <div v-if="submit" class="pt-6">
        <div v-if="invalidSlideIndices.length > 0 && !onlyLastSlideInvalid" class="text-red-500 p-2">
          Please finish all required fields.
          {{ invalidSlideIndices.length }} {{ invalidSlideIndices.length > 1 ? 'slides are' : 'slide is' }} incomplete.
        </div>
        <InputCheckbox
          v-model="termsAgreement"
          class="text-sm inline-flex text-not-so-white ml-2 mb-5"
          has-html-label
        >
          <span class="pl-2 font-monument leading-5">
            By submitting, you agree to Enigma's <a class="text-space-orange underline" href="https://enigmalabs.io/terms" target="_blank">terms of service</a> and <a class="text-space-orange underline" href="https://enigmalabs.io/privacy" target="_blank">privacy policy</a>.
          </span>
        </InputCheckbox>
        <ControlSubmit :disabled="!allSlidesValid || !termsAgreement" />
      </div>
    </div>

    <div v-if="!submitted" class="hidden md:flex items-center max-h-screen">
      <ControlPagination class="hidden sm:block" />
    </div>
    <slot name="right" />
  </div>
</template>
<script setup lang="ts">
const { allSlidesValid, invalidSlideIndices, termsAgreement } = useNavigation()
const onlyLastSlideInvalid = invalidSlideIndices.value.length === 1 && invalidSlideIndices.value[0] === 9

const props = defineProps({
  forward: {
    type: Boolean,
    default: () => true
  },
  back: {
    type: Boolean,
    default: () => false
  },
  forwardDisabled: {
    type: Boolean,
    default: () => false
  },
  submit: {
    type: Boolean,
    default: () => false
  },
  uploading: {
    type: Boolean,
    default: () => false
  },
  submitted: {
    type: Boolean,
    default: () => false
  },
  singlePane: {
    type: Boolean,
    default: () => false
  }
})

</script>
